import { useMenu } from '../../../provider/menu';

import { Burger } from '../Burger';

import Logo from '../../../assets/logo-srgc-h.svg';

import * as S from './styled';

export const Title = ({ value }) => {
  const { isOpen, setIsOpen } = useMenu();

  return (
    <S.Container>
      <S.Title>
        <Burger onClick={() => setIsOpen(!isOpen)} />
        <h1>{value}</h1>
      </S.Title>
      <img src={Logo}/>
    </S.Container>
  )
}
