import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #aaa;
`;

export const Wrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
`;

export const Logo = styled.img`
  width: 90%;
  margin-top: 60px;
  max-width: 340px;
  margin: horizontal 20px;
`;

export const Form = styled.form`
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: #000;
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 350px;
  text-align: center;
  font-size: 50px;
  margin-top: 50px;
  padding: 10px 0;
  color: #fff;
  background-color: #000;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  
  input {
    background-color: #aaa;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;

  div {
    margin: 0;
  }

  a {
    margin: 0;
    margin-right: 28px;
  }

  .recovery {
    div > div {
      width: 510px;
    }
  }
`;