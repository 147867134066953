import React from 'react';
import ReactDOM from 'react-dom';
import { ResetCss } from './ResetCss';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertMUITemplate from "react-alert-template-mui";

import { AuthProvider } from './provider/auth';
import { MenuProvider } from './provider/menu';

import { Layout } from './components/Layout';

const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: '20px',
  transition: transitions.FADE,
}

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertMUITemplate} {...options}>
      <AuthProvider>
        <MenuProvider>
            <ResetCss />
            <Layout />
        </MenuProvider>
      </AuthProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);