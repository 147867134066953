import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  display: block;

  :hover {
    filter: brightness(110%);
  }

  :active {
    filter: brightness(120%)
  }

  a {
    width: 100%;
    height: 100%;
    padding: 5px 16px;
    cursor: pointer;
    margin-bottom: 3px;
    background-color: #343A40;
    list-style: none;
    display: flex;
  }

  .logout {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #343A40;
    border: none;
    color: inherit;
    font-size: 20px;
  }

  .logout svg {
    margin-right: 5px;
  }
`;