import styled from "styled-components";

export const Container = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  button {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 10px 15px;
    border: none;
    color: #fff;
    cursor: pointer;
    
    svg {
      color: #fff;
    }
  }

  a {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 9px;
    border: none;
    color: #fff;
    cursor: pointer;
  }

  .link {
    padding: 0;
    font-size: 16px;
    font-weight: inherit;
    background-color: #fff;
    color: #000;
  }

  .login {
    background-color: #1D1d1d;
  }

  .save {
    background-color: #009500;
  }
  
  .common {
    background-color: #00aaff;
  }
  
  .warning {
    background-color: #ffa500;
    color: #000;
  }
  
  .common-svg {
    width: 100%;
    background-color: #00aaff;
    svg {
      color: #000;
    }
  }
  
  .warning-svg {
    width: 100%;
    background-color: #ffa500;
    color: #000;
    svg {
      color: #000;
    }
  }
  
  .disable {
    background-color: #1D1F20;
    color: #f00;
    pointer-events:none;
    cursor: not-allowed;
  }

  .delete {
    background-color: #aa0000;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 5px;
    button {
      width: 90%;
    }
  }
`;
