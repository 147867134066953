import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

import { Button } from '../../dumbs/Button';
import { InputLink } from '../../dumbs/Input';
import { Title } from '../../dumbs/Title';
import { Alert } from '../../dumbs/Alert';
import { SectionTitle } from '../../dumbs/SectionTitleInput';

import api from '../../../services/api';

import * as S from './styled';

export const SocialMedias = () => {
  const [ socialMediaId, setSocialMediaId ] = useState('');
  const [ facebook, setFacebook ] = useState('');
  const [ instagram, setInstagram ] = useState('');
  const [ booking, setBooking ] = useState('');

  const [sectionTitle, setSectionTitle] = useState('');

  const alert = useAlert();

  const token = Cookies.get("token");

  useEffect( async () => {
    if (token) {
      await api.get('/social-medias')
      .then(response  => {
        setSocialMediaId(response.data.socialMedias._id);
        setSectionTitle(response.data.socialMedias.sectionTitle)
        setFacebook(response.data.socialMedias.facebook);
        setInstagram(response.data.socialMedias.instagram);
        setBooking(response.data.socialMedias.twitter);
      })
      .catch(() => {
        return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
      })
    }
  }, [])
  
  const handleSave = async () => {
    const data = {
      _id: socialMediaId,
      sectionTitle,
      facebook,
      instagram,
      twitter: booking,
    }

    api.defaults.headers.authorization = token;
    await api.put('/social-medias', data)
    .then(() => {
      return alert.show(<Alert type='success'>Links atualizados com sucesso!</Alert>);
    })
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
    });
  }

  return (
    <S.Wrapper>
      <Title value="Redes Sociais" />
      <S.Container>
      <SectionTitle onChange={event => setSectionTitle(event.target.value)} value={sectionTitle} sectionName='Redes Sociais' />
        <S.Model>
          <S.Bar />
          <S.Inputs>
            <S.MediaSocial>
              <S.MediaName>Reservas</S.MediaName>
              <InputLink label='Link' value={booking} onChange={event => setBooking(event.target.value)} type="text" />
            </S.MediaSocial>
            <S.MediaSocial>
              <S.MediaName>Facebook</S.MediaName>
              <InputLink label='Link' value={facebook} onChange={event => setFacebook(event.target.value)} type="text" />
            </S.MediaSocial>
            <S.MediaSocial>
              <S.MediaName>Instagram</S.MediaName>
              <InputLink label='Link' value={instagram} onChange={event => setInstagram(event.target.value)} type="text" />
            </S.MediaSocial>
          </S.Inputs>
          <S.Button>
            <Button type='save' className="btn" onClick={handleSave}>Salvar</Button>
          </S.Button>
        </S.Model>
      </S.Container>
    </S.Wrapper>
  )
}
