import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  background-color: #111;

  button {
    margin: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111;

  button {
    margin: 0;
  }

  .buttonless {
    padding: 22px 0;
    width: 100%;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: #343A40;
  width: 100%;
  height: 45px;
  
  span {
    width: 100%;
    display: flex;
    font-size: 20px;
    font-weight: 700;
  }
`;
  
  export const Input = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 90%;

    
    input {
      width: 90%;
      padding: 8px 10px;
      font-size: 18px;
      font-weight: 500;
      border: none;
    }
    
    @media screen and (max-width: 600px) {
      width: 100%;
      flex-direction: column;
    }
`;