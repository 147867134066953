import styled from "styled-components";

export const Alert = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 10px;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bolder;
        align-self: center;
        position: absolute;
        margin-top: 45px;
        padding: 0 15px;

        svg {
            margin-right: 5px;
        }
    }

    .error {
        color: #ff0000;
    }
    
    .success {
        color: #009501;
    }
`;

export const SuccessAlert = styled.div`
    
`;