import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

import api from '../../../services/api';

import { Button } from '../../dumbs/Button';
import { Input, InputPhone } from '../../dumbs/Input';
import { Title } from '../../dumbs/Title';
import { Alert } from '../../dumbs/Alert';

import * as S from './styled';

export const EnterpriseSettings = () => {
  const [ settingsId, setSettingsId ] = useState('');
  const [ enterpriseName, setEnterpriseName ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ whatsapp, setWhatsapp ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ email, setEmail ] = useState('');

  const alert = useAlert();

  const token = Cookies.get("token");

  useEffect( async () => {
    if (token) {
        api.defaults.headers.token =  token;
        await api.get('/settings')
        .then(response => {
          setSettingsId(response.data.settings._id);
          setEnterpriseName(response.data.settings.enterpriseName);
          setPhone(response.data.settings.phone);
          setWhatsapp(response.data.settings.whatsapp);
          setAddress(response.data.settings.address);
          setEmail(response.data.settings.email);
        })
        .catch(() => {
          return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
        })
      }
    }, [])
    
    const handleSave = async (event) => {
      event.preventDefault();
      
      const data = {
        _id: settingsId,
        enterpriseName,
        phone,
        whatsapp,
        address,
        email,
      }
      
      api.defaults.headers.authorization = token ;
      await api.put('/settings', data)
      .then(() => {
        return alert.show(<Alert type='success'>Dados atualizados com sucesso!</Alert>);
      })
      .catch(() => {
        return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
      });
  }
  
  return (
    <S.Wrapper>
      <Title value="Dados da Empresa" />
      <S.Container>
        <S.Bar />
        <S.Form>
          <Input label="Nome da Empresa" value={enterpriseName} onChange={event => setEnterpriseName(event.target.value)} type="text" />
          <InputPhone label="Telefone" value={phone} onChange={event => setPhone(event.target.value)} type="text" />
          <InputPhone label="Whatsapp" value={whatsapp} onChange={event => setWhatsapp(event.target.value)} type="text" />
          <Input label="Endereço" value={address} onChange={event => setAddress(event.target.value)} type="text" />
          <Input label="E-mail" value={email} onChange={event => setEmail(event.target.value)} type="text" />
          <S.Button>
            <Button onClick={event => handleSave(event)} type="save">Salvar</Button>
          </S.Button>
        </S.Form>
      </S.Container>
    </S.Wrapper>
  )
}
