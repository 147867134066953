import { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button } from '../../../components/dumbs/Button';
import { Input } from '../../../components/dumbs/Input';
import api from '../../../services/api';

import * as S from './styled';

export const ModalRecovery = () => {
  const [ recoveryEmail, setRecoveryEmail ] = useState('');

  const alert = useAlert();

  const postRecovery = async () => {
    const data = {
      recoveryEmail
    }
    await api.post('/recovery', data)
    .then(() => {
      alert.show("Foi enviado e-mail de recuperação.", {type: 'success'});
    })
    .catch((err) => {
      if(err.status === 401) {
        alert.show("E-mail informado não esta cadastrado", {type: 'error'});
      } else {
        alert.show("Erro de conexão com servidor", {type: 'error'});
      }
    })
  }

  return (
    <S.Container>
      <Input label='Informe o e-mail cadastrado' onChange={event => setRecoveryEmail(event.target.value)}/>
      <Button type='save' onClick={postRecovery}>ENVIAR</Button>
    </S.Container>
  )
}
