import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.form`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  background-color: #111;
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: #343A40;
  width: 100%;
  height: 45px;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 10px;
  `;
  
export const Button = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;