import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

import { Button } from '../../dumbs/Button';
import { Input } from '../../dumbs/Input';
import { Title } from '../../dumbs/Title';
import { Alert } from '../../dumbs/Alert';

import api from '../../../services/api';

import * as S from './styled';

export const Theme = () => {
  const [ themeId, setThemeId ] = useState('');
  const [ primary, setPrimary ] = useState('');
  const [ secondary, setSecondary ] = useState('');

  const token = Cookies.get("token");
  
  const alert = useAlert();
  
  const handleSave = async () => {    
    const data = {
      _id: themeId,
      primary,
      secondary,
    }
    
    api.defaults.headers.authorization = token;
    await api.put('/theme', data)
    .then(() => {
      return alert.show(<Alert type='success'>Cores atualizadas com sucesso!</Alert>);
    })
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
    });
  }
  
  useEffect(() => {
    const getColors = async () => {
      if (token) {
        return await api.get('/theme')
        .then(response => {
          setThemeId(response.data.theme._id);
          setPrimary(response.data.theme.primary);
          setSecondary(response.data.theme.secondary);
        })
        .catch(() => {
          return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
        })
      }
    }
    getColors();
  }, []);

  return (
    <S.Wrapper>
      <Title value="Cores do Tema" />
      <S.Container>
        <S.Bar />
        <S.Inputs>
          <Input label="Cor primária" value={primary} onChange={event => setPrimary(event.target.value)} type="color"/>
          <Input label="Cor secundária" value={secondary} onChange={event => setSecondary(event.target.value)} type="color"/>
        </S.Inputs>
        <S.Button>
          <Button type="save" onClick={handleSave}>Salvar</Button>
        </S.Button>
      </S.Container>
    </S.Wrapper>
  )
}
