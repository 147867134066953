import { NavItem, NavLogout } from '../dumbs/NavItem';
import { User } from '../dumbs/User';

import Cookies from 'js-cookie';

import { IoLogOutOutline } from 'react-icons/io5'

import { useAuth } from '../../provider/auth';

import * as S from './styled';

export const SideBar = () => {
  const { setUser } = useAuth();

  const handleLogout = () => {
    Cookies.remove('token');
    setUser({token: ''});
  }

  return (
    <S.Container id="outer-container">         
          <NavItem path="/usuario">
            <User />
          </NavItem>
          <S.Separator />
          <S.Menu>
            <NavItem path="/home">Home</NavItem>
            <NavItem path="/banner">Banner</NavItem>
            <NavItem path="/galerias">Galerias</NavItem>
            <NavItem path="/redes-sociais">Redes Sociais</NavItem>
            <NavItem path="/tema">Cores do Tema</NavItem>
            <NavItem path="/dados-empresa">Dados da Empresa</NavItem>
          </S.Menu>
          <S.Logout>
            <NavLogout path="/login" onClick={handleLogout}><IoLogOutOutline size="24" />Logout</NavLogout>
          </S.Logout>
          <S.Footer>
            <span>SRGC - Sistemas</span>
            <p>Copyright © 2021</p>
          </S.Footer>
      </S.Container>
  )
}
