import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
`;

export const Image = styled.div`
  svg {
    margin-right: 15px;
    border-radius: 50%;
    filter: drop-shadow(3px 4px 1px #000);
  }
`;

export const UserName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  span {
    font-size: 16px;
  }
`;