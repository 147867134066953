import styled from "styled-components";

export const Wrapper = styled.div`
  filter: drop-shadow(5px 5px 6px  rgba(9,9,9,1));
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eee;
  `;

export const Form = styled.form`
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: #000;
`;

export const Title = styled.h1`
  width: 350px;
  text-align: center;
  font-size: 50px;
  margin-top: 150px;
  padding: 10px 0;
  color: #fff;
  background-color: #343A40;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  input {
    background-color: #ccc;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  div {
    margin: 0;
  }

  button {
    margin: 0;
    margin-right: 18px;
  }
`;