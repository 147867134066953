import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111;
  margin-top: 30px;
`;

export const Container = styled.div`
  width: 90%;
`;

export const GalleryTittle = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: #343A40;
  h4 {
    font-size: 18px;
  }
`;

export const NameGallery = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 20px 0;
  
  button {
    margin: 0;
  }

  @media screen and (max-width: 1025px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  @media screen and (max-width: 600px) {
    align-items: center;
  }
`;

export const Input = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  
  @media screen and (max-width: 1025px) {
    justify-content: center;
    align-items: flex-end;
  }
  
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 540px) {
    flex-direction: column-reverse;
    align-items: center;
    button {
      margin: 0;
    }
  }
`;

export const ImageButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ImageList = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 275px;

  @media screen and (max-width: 769px) {
    justify-content: space-around;
  }
`;

export const ImageDisplay = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
`;