import { createGlobalStyle } from 'styled-components';

export const ResetCss = createGlobalStyle`
    *,
    ::before,
    ::after {
        outline: 0;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }

    body {
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }

    body ::-webkit-scrollbar {
        width: 12px;
        overflow: auto;
        
        
    }

    body ::-webkit-scrollbar-thumb {
        background-color: #343A40;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
`;