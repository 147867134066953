import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { useAuth } from '../../provider/auth';

import api from '../../services/api';
import { emailValid, passwordValid } from '../../services/formsValidations';
import { dbValidationLogin } from '../../services/dbValidations';

import { ButtonLink, NavButton } from '../../components/dumbs/Button';
import { Input } from '../../components/dumbs/Input';
import { Alert } from '../../components/dumbs/Alert';

import { ModalRecovery } from './ModalRecovery';

import SrgcLogo from '../../assets/logo-srgc-h.svg'

import * as S from './styled';

import { useAlert } from 'react-alert';

export const Login = () => {
  const history = useHistory();
  
  const { user, setUser } = useAuth();

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const isEmail = emailValid(email);
  const isPassword = passwordValid(password);

  const alert = useAlert();
  
  useEffect(()  =>  {
    if(Cookies.get("token")) {
      setUser({token: Cookies.get("token")});
      return history.push('/home');
    } else {
      setUser({token: ''});
    }
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    if(isEmail) {
      return alert.show(<Alert type='error'>{isEmail.msg}</Alert>);
    }
    if(isPassword) {
      return alert.show(<Alert type='error'>{isPassword.msg}</Alert>);
    }
    
    const data = {
      email,
      password,
    }

    api.post('/user/signin', data)
    .then(response => {
      Cookies.set("token", response.data.token);
      setUser({
        name: response.data.name,
        token: response.data.token,
      });
      alert.show(<Alert type='success'>Olá, {response.data.name}</Alert>);
      return history.push('/home');
    }).catch(errors => {
      const errorMsg = dbValidationLogin(errors);
      return alert.show(<Alert type='error'>{errorMsg.msg}</Alert>);
    });
  }
  
  return (
    <>
      {
        user.token === '' ?
        <S.Container>
          <S.Logo src={SrgcLogo} />
          <S.Wrapper>
            <S.Title>Login</S.Title>
            <S.Form>
              <S.Inputs>
                <Input label="E-mail" type="text" onChange={event => setEmail(event.target.value)} />
                <Input label="Senha" type="password" onChange={event => setPassword(event.target.value)} />
              </S.Inputs>
              <S.Buttons>
                <NavButton path='/home' type="login" onClick={event => handleLogin(event)}>Entrar</NavButton>
                <ButtonLink onClick={() => {
                  alert.removeAll()
                  alert.show(
                    <ModalRecovery />
                    ,{
                      title: "RECUPERAÇÃO DE SENHA",
                      timeout: false,
                      closeCopy: "CANCELAR",
                    });
                }}>Recuperar senha</ButtonLink>
              </S.Buttons>
            </S.Form>
          </S.Wrapper>
        </S.Container>
        : <></>
      }
    </>
  )
}
