import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Bar = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  background-color: #343A40;
`;

export const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111;
  margin: 30px 0;
  
  input {
    height: 100px;
    cursor: pointer;
  }
`;

export const Inputs = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 628px) {
    flex-direction: column;
  } 
`;

export const Button = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;
