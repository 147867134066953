import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

import { Button } from '../../dumbs/Button';
import { Input } from '../../dumbs/Input';
import { Title } from '../../dumbs/Title';
import { Alert } from '../../dumbs/Alert';

import { useAuth } from '../../../provider/auth';

import api from '../../../services/api';
import { emailValid } from '../../../services/formsValidations';

import * as S from './styled';

export const UserEdit = () => {
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const { user } = useAuth();

  const alert = useAlert();

  const token = Cookies.get("token");

  const handleSave = async (event) => {
    event.preventDefault();

    const isEmail = emailValid(email);

    if(isEmail) {
      return alert.show(<Alert type='error'>{isEmail.msg}</Alert>); 
    }

    const data = {
      name,
      email,
      password,
    }

    api.defaults.headers.authorization = user.token;
    await api.put('/user', data)
    .then(() => {
      return alert.show(<Alert type='success'>Dados atualizados com sucesso!</Alert>); 
    })
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>); 
    });
  }

  useEffect( async () => {
    if (token) {
      api.defaults.headers.token =  token;
      await api.get('/user')
      .then(response => {
        setName(response.data.name);
        setEmail(response.data.email);
      })
      .catch(() => {
        return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
      })
    }
  }, [])

  return (
    <S.Wrapper>
      <Title value="Editar Usuário" />
      <S.Container>
        <S.Bar />
        <S.Inputs>
          <Input label="Nome de Usuário" value={name} onChange={event => setName(event.target.value)} type="text" />
          <Input label="E-mail" value={email} onChange={event => setEmail(event.target.value)} type="email" />
          <Input label="Password" onChange={event => setPassword(event.target.value)} type="password" />
        </S.Inputs>
        <S.Button>
          <Button onClick={event => handleSave(event)} type="save">Salvar</Button>
        </S.Button>
      </S.Container>
    </S.Wrapper>
  )
}
