import InputMask from "react-input-mask";

import * as S from './styled';

export const Input = ({ label, type, onChange, value, disabled }) => {
  return (
    <S.Container>
      <span>{label}</span>
      <input type={type} disabled={disabled} onChange={onChange} value={value}/>
    </S.Container>
  )
}

export const InputPhone = ({ label, type, onChange, value }) => {
  return (
    <S.Container>
      <label>{label}</label>
      <InputMask type={type} mask="(99) 9999-9999" value={value} onChange={onChange} />
    </S.Container>
  )
}

export const InputWhatsapp = ({ label, type, onChange, value, isMobile }) => {
  return (
    <S.Container>
      <label>{label}</label>
      {
      isMobile ? 
      <InputMask type={type} mask="(99) 9 9999-9999" value={value} onChange={onChange} />
      :
      <InputMask type={type} mask="(99) 9999-9999" value={value} onChange={onChange} />

      }
    </S.Container>
  )
}

export const InputLink = ({ label, type, onChange, value }) => {
  return (
    <S.ContainerLink>
      <label>{label}</label>
      <InputMask type={type} placeholder="https://" value={value} onChange={onChange} />
    </S.ContainerLink>
  )
}
