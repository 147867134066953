import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;

  .link {
    margin: 0;
  }
  
  span {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    padding: 8px 10px;
    font-size: 18px;
    font-weight: 500;
    border: none;
  }

  @media screen and (max-width: 600px) {
    align-items: center;
    width: 90%;
  }
`;

export const ContainerLink = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .link {
    margin: 0;
  }
  
  span {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    padding: 8px 10px;
    font-size: 18px;
    font-weight: 500;
    border: none;
  }
`;

export const ContainerSectionName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  
  input {
    width: 100%;
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
  }
`;