import Cookies from 'js-cookie';
import { createContext, useState, useContext } from 'react';

export const AuthContext = createContext({});

export const AuthProvider = (props) => {
    const [user, setUser] = useState({
        name: '',
        email: '',
        token: Cookies.get('token'),
    });

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {props.children}
        </AuthContext.Provider>

    );
};

export const useAuth = () => useContext(AuthContext);