export const dbValidationLogin = ( errors ) => {
    const status = errors.response.status;
    let alert = {type: '', msg: ''};
    switch (status) {
        case 400:
            alert = {
                type: 'error',
                msg: 'Dados inválidos!'
            };
            return alert;
        case 401:
            alert = {
                type: 'error',
                msg: 'E-mail não cadastrado!'
            };
            return alert;
        case 402:
            alert = {
                type: 'error',
                msg: 'Senha está incorreta!'
            };
            return alert;
        default:
            alert = {
                type: 'error',
                msg: 'Um erro inesperado aconteceu!'
            };
            return alert; 
    }
}

export const dbValidationUserUpdate = ( errors ) => {
    const status = errors.response.status;
    let alert = {type: '', msg: ''};
    switch (status) {
        case 400:
            alert = {
                type: 'error',
                msg: 'E-mail inválido!'
            };
            return alert;
        default:
            alert = {
                type: 'error',
                msg: 'Erro de conexão com servidor!'
            }
            return alert;
    }
}