import styled from 'styled-components';

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0 15px;
  
  div {
    width: 30px;
    height: 4px;
    background: #fff;
    border-radius: 10px;
  }
`;