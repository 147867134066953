import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu';
import Cookies from 'js-cookie';

import { SideBar } from '../SideBar';

import { Login } from '../../Pages/Login';
import { RecoveryLogin } from '../../Pages/RecoveryLogin';

import { Home } from '../sections/Home';
import { UserEdit } from '../sections/UserEdit';
import { EnterpriseSettings } from '../sections/EnterpriseSettings';
import { Galleries } from '../sections/Galleries';
import { Banner } from '../sections/Banner';
import { SocialMedias } from '../sections/SocialMedias';
import { Theme } from '../sections/Theme';

import api from '../../services/api';

import { useAuth } from '../../provider/auth';
import { useMenu } from '../../provider/menu';

import { Alert } from '../dumbs/Alert';

import * as S from './styled';

export const Layout = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [screen, setScreen] = useState('');

  const { isOpen } = useMenu();
  const { user, setUser } = useAuth();

  const history = useHistory();

  const getScreen = () => {
    setScreen(document.documentElement.clientWidth);
  }

  window.addEventListener('resize', () => {
    getScreen();
  });
  
  useEffect(() => {
    if(screen < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screen]);
  
  const getUser = async () => {
    api.defaults.headers.authorization = user.token;
    await api.get('/user')
    .then(response =>
      setUser({
        name: response.data.name,
        email: response.data.email,
      })
      
    )
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
    })
  }

  useEffect(() => {
    const checkLogin = () => {
      if(!Cookies.get('token')) {
        setUser({
          name: '',
          email: '',
          token: '',
        });
      } else {
        getUser();
      }
    }
    checkLogin();
    getScreen();
  }, []);
  
  return (
    <Router history={useHistory}>
      <S.Layout>
        {
          user.token !== '' ?
          <>
            {
              isMobile ?
              <S.Menu id={'outer-container'}>
                <Menu
                  outerContainerId={ 'outer-container' }
                  pageWrapId={'sections'}
                  reveal={ true }
                  width={250}
                  disableCloseOnEsc 
                  left 
                  isOpen={ isOpen }
                  noOverlay
                  customBurgerIcon={false}
                >
                  <SideBar  isOpen={isOpen} />
                </Menu>
              </S.Menu>
              :
              <SideBar />
            }
          </>
        :<></>
        }
        <S.Container id={'sections'}>
          <Switch>
              <Route exact path="/home">
                {Cookies.get('token') ? <Home /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/usuario">
                {Cookies.get('token') ? <UserEdit /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/dados-empresa">
                {Cookies.get('token') ? <EnterpriseSettings /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/galerias">
                {Cookies.get('token') ? <Galleries /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/banner">
                {Cookies.get('token') ? <Banner /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/redes-sociais">
                {Cookies.get('token') ? <SocialMedias /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/tema">
                {Cookies.get('token') ? <Theme /> : <Redirect to="/login" />}
              </Route>

              <Route exact component={ Login } path="/login"  />
              <Route exact component={ Login } path="/"  />
              <Route component={ RecoveryLogin } path="/recovery/:id"  />
          </Switch>
        </S.Container>
      </S.Layout>
    </Router>
  )
}