import * as S from './styled'

export const Burger = ({ onClick }) => {
  return (
    <S.StyledBurger onClick={onClick}>
      <div />
      <div />
      <div />
    </S.StyledBurger>
  )
}