export const emailValid = (value) => {
  if(value === '') return {
    type: 'error',
    msg: 'Preencha o campo de e-mail!'
  };
  if (!(value.includes("@") && value.includes(".com"))) {
    return{
      type: 'error',
      msg: 'Formato de E-mail inválido!'
    };
  }
};

export  const passwordValid = (value) => {
  if(value === '') return {
    type: 'error',
    msg: 'Preencha todos os campos!'
  };
  if (value.length < 4) return {
    type: 'error',
    msg: 'A senha precisa de 4 caracteres!'
  };
};