import { Link } from "react-router-dom";

import { useMenu } from '../../../provider/menu';

import * as S from './styled';

export const NavItem = ({ children, path }) => {

  const { isOpen, setIsOpen } = useMenu();

  return (
    <S.Container onClick={() => setIsOpen(!isOpen)}>
      <Link to={path}>{children}</Link>
    </S.Container>
  )
}

export const NavLogout = ({ children, path, onClick }) => {
  return (
    <S.Container>
      <Link className="logout" to={path} onClick={onClick}>{children}</Link>
    </S.Container>
  )
}
