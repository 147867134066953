import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 80%;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
`;

export const Frame = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    min-height: 120vh;
    border: none;
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: #343A40;
`;