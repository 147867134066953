import axios from 'axios';

export const BASE_API = "https://api.hotelkolman.com";

export const BASE_MAIN = "https://hotelkolman.com";

const api = axios.create ({
    baseURL: BASE_API,
});

export default api;