import { Button } from '../Button';
import { Input, InputSectionName } from '../Input';

import * as S from './styled';

export const SectionTitleInput = ({ onChange, value, onClick, sectionName }) => {
  return (
    <S.Wrapper>
        <S.Label>
          <span>{`Texto da sessão ${sectionName}`}</span>
        </S.Label>
      <S.Container>
        <S.Input>
          <input type='text' onChange={onChange} value={value}/>
          <Button type="save" onClick={onClick}>Salvar</Button> 
        </S.Input>
      </S.Container>
    </S.Wrapper>
  )
}

export const SectionTitle = ({ onChange, value, onClick, sectionName }) => {
  return (
    <S.Wrapper>
        <S.Label>
          <span>{`Texto da sessão ${sectionName}`}</span>
        </S.Label>
      <S.Container>
        <S.Input className='buttonless'>
          <input type='text' onChange={onChange} value={value}/>
        </S.Input>
      </S.Container>
    </S.Wrapper>
  )
}
