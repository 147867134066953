import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #343a40;
  color: #fff;
  padding-top: 10px;

  :nth-last-child() {
    justify-self: flex-end;
  }
`;

export const Separator = styled.div`
  width: 80%;
  height: 1px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 10px;
`;

export const Menu = styled.ul`
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

export const Logout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 14px;
  color: #999;
  span {
    margin-bottom: 5px;
  }
`;