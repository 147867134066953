import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';

import * as S from './styled';

export const Alert = ({ type, children }) => {
  const icon = {
    error: <BsFillXCircleFill size={30} />,
    success: <BsFillCheckCircleFill size={30} />
  }
  return (
    <S.Alert >
      <span className={type}>{icon[type]} {children}</span>
    </S.Alert>
  );
};
