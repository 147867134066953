import styled from "styled-components";

export const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #222;
  color: #fff;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #222;
  color: #fff;
`;

export const Menu = styled.div`
  height: 100%;
`;