import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import ImageUploading from 'react-images-uploading';

import { HiRefresh } from 'react-icons/hi';
import { MdDeleteForever } from 'react-icons/md';

import { Button } from '../../dumbs/Button';
import { Alert } from '../../dumbs/Alert';
import { Title } from '../../dumbs/Title';

import api, { BASE_API } from '../../../services/api'

import * as S from './styled';

export const Banner = () => {
  const [images, setImages] = useState([]);
  const maxNumber = 10;
  const onChange = (imageList) => {
    setImages(imageList);
  };

  const token = Cookies.get("token");

  const alert = useAlert();

  const replace = `${BASE_API}/public/`;

  const handleSave = async () => {
    try {
      if (images.length === 0) {
        return alert.show(<Alert type='error'>Adicione ao menos uma imagem para salvar.</Alert>);
      }

      const imagesName = [];
      for (const value of images ) {
        if (value.data_url.includes("public")) {
          const dataURL = value.data_url;
          imagesName.push(dataURL.substring(dataURL.indexOf(replace) + replace.length));
        }
      }
      
      const data = {
        data: { 
          galleryName: 'banner',
          imagesName
        }
      }
      api.defaults.headers.token =  token;
      await api.delete('/images', data)
      .then(async () => {
        for ( const value of images) {
          const data = new FormData();
          data.append('file', value.file);
          data.append('gallery', 'banner');
          api.defaults.headers.token =  token;
          await api.post('/gallery', data)
        }
        return alert.show(<Alert type='success'>Banner atualizado com sucesso!</Alert>);
      })
      .catch(() => {
        return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
      })
    }
    catch {
      return alert.show(<Alert type='error'>Erro inesperado aconteceu!</Alert>);
    }
  }
  
  useEffect(() => {
    const getImages = async () => {
      await api.get('/slide')
      .then(response => {
        response.data.data.map((path) => {return setImages(events => [...events, {
          data_url: `${BASE_API}/public/${path}`
        }])});
      })
      .catch(() => {
        return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
      })
    }
    getImages();
  }, []);

  return (
    <S.Wrapper>
      <Title value="Banner" />
      <S.Container>
        <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        >{({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
          }) => (
            <S.Window>
              <S.Buttons>
                <Button
                  type={images.length >= maxNumber ? 'disable' : 'common'}
                  onClick={onImageUpload}
                >
                  + Imagens
                </Button>

                <Button type="save" onClick={handleSave}>Salvar</Button> 

                <Button type="delete" onClick={onImageRemoveAll}>Limpar Tudo</Button> 
              </S.Buttons>
              <S.ImageList>
                {imageList.map((image, index) => (
                  <S.ImageDisplay key={index}>
                    <img src={image.data_url} alt="imagem" height="300" />
                    <S.ImageButtons>
                      <Button type="common-svg" onClick={() => onImageUpdate(index)}><HiRefresh /></Button>
                      <Button type="warning-svg" onClick={() => onImageRemove(index)}><MdDeleteForever /></Button>
                    </S.ImageButtons>
                  </S.ImageDisplay>
                ))}
              </S.ImageList>
            </S.Window>          
          )}
        </ImageUploading>
      </S.Container>
    </S.Wrapper>
  )
}
