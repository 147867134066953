import { FaUserCircle } from 'react-icons/fa';

import { useAuth } from '../../../provider/auth';

import * as S from './styled';

export const User = () => {

  const { user } = useAuth();

  return (
    <S.Container>
      <S.Image>
        <FaUserCircle size="30" />
      </S.Image>
      <S.UserName>
        <span>{user.name}</span>
      </S.UserName>
    </S.Container>
  )
}