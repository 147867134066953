import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

import { Title } from '../../dumbs/Title';
import { Alert } from '../../dumbs/Alert';
import { SectionTitleInput } from '../../dumbs/SectionTitleInput';

import api from '../../../services/api';

import { CardGallery } from './CardGallery';

import * as S from './styled';
import { Footer } from '../../dumbs/Footer';

export const Galleries = () => {
  const [sectionTitle, setSectionTitle] = useState('');

  const alert = useAlert();

  const token = Cookies.get("token");

  const handleSave = async () => {
    const data = {
      title: sectionTitle,
      section: "gallery"
    };

    api.defaults.headers.authorization = token;
    await api.put('/section', data)
    .then(() => {
      return alert.show(<Alert type='success'>Título atualizado com sucesso!</Alert>);
    })
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
    })
  };

  useEffect(() => {
    const getTitle = async () => {
      await api.get('/section')
      .then(response => {
        return setSectionTitle(response.data.section.title);
      })
      .catch(() => {
        return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
      })
    }
    getTitle();
  }, [])

  return (
    <S.Wrapper>
      <Title value="Galerias" />
      <S.Container>
        <SectionTitleInput onClick={handleSave} onChange={event => setSectionTitle(event.target.value)} value={sectionTitle} sectionName='Galerias' />
        <CardGallery name="gallery1" pTitle=""  />
        <CardGallery name="gallery2" pTitle=""  />
        <CardGallery name="gallery3" pTitle=""  />
        <CardGallery name="gallery4" pTitle=""  />
        <CardGallery name="gallery5" pTitle=""  />
      </S.Container>
    </S.Wrapper>
  )
}