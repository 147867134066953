import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Container = styled.div`
  width: 100%;
  height: 90%;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
  
  button {
    margin: 0;
  }
`;

export const Window = styled.div`
  width: 90%;
  display: flex;
  margin: 30px 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #111;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #343A40;

  @media screen and (max-width: 540px) {
    flex-direction: column-reverse;
    align-items: center;
    button {
      margin: 0;
    }
  }
`;

export const ImageButtons = styled.div`
  width: 100%;
  height: 45px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageList = styled.div`
  width: 90%;
  height: 100%;
  min-height: 275px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  
  @media screen and (max-width: 1025px) {
    justify-content: space-around;

  }
`;

export const ImageDisplay = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
`;