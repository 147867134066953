import { BASE_MAIN } from '../../../services/api';

import { Title } from '../../dumbs/Title';

import * as S from './styled';

export const Home = () => {
  return (
    <S.Wrapper>
      <Title value='Bem vindo!' />
      <S.Container>
        <S.Frame>
          <S.Bar>
            <h3>PRÉVIA</h3>
          </S.Bar>
          <iframe src={BASE_MAIN} />
        </S.Frame>
      </S.Container>
    </S.Wrapper>
  )
}