import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

export const Model = styled.div`
  margin-top: 30px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111;
  margin-bottom: 30px;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 10px;

  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const MediaSocial = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
`;

export const MediaName = styled.h2`
  font-size: 40px;
  text-align: left;
`;

export const Bar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #343A40;
  height: 45px;
`;

export const Button = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;