import { createContext, useState, useContext } from 'react';

export const MenuContext = createContext({});

export const MenuProvider = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <MenuContext.Provider value={{ isOpen, setIsOpen }}>
            {props.children}
        </MenuContext.Provider>
    );
};

export const useMenu = () => useContext(MenuContext);