import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  background-color: #111;

  label {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

export const Form = styled.form`
  width: 90%;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .save button {
    margin-right: 0;
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: #343A40;
`;