import { Link } from 'react-router-dom';
import * as S from './styled';

export const Button = ({ children, type, onClick }) => {
  return (
    <S.Container>
      <button className={type} onClick={onClick} >{children}</button>
    </S.Container>
  )
}

export const NavButton = ({ type, children, path, onClick }) => {
  return (
    <S.Container>
      <Link className={type} onClick={onClick} to={path}>{children}</Link>
    </S.Container>
  )
}

export const ButtonLink = ({ children, onClick }) => {
  return (
    <S.Container>
      <button type='button' className='link' onClick={onClick} >{children}</button>
    </S.Container>
  )
}
