import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  background-color: #343A40;

  img {
    width: 190px;
    margin-right: 30px;
    filter: invert(1);
  }

  @media screen and (min-width: 769px) {
    button {
      display: none;
    }
  }

  @media screen and (max-width: 501px) {
    img {
      display: none;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;