import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

import { HiRefresh } from 'react-icons/hi';
import { MdDeleteForever } from 'react-icons/md';

import api, { BASE_API } from '../../../../services/api';

import { Button } from '../../../dumbs/Button';
import { Input } from '../../../dumbs/Input';
import { Alert } from '../../../dumbs/Alert';

import * as S from './styled';

export const CardGallery = ({ name, pTitle }) => {
  const [title, setTitle] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);
  const [gallery, setGallery] = useState([]);

  const maxNumber = 10;
  const onChange = (imageList) => {
    setGalleryImages(imageList);
  };

  const token = Cookies.get("token");

  const alert = useAlert();

  const replace = `${BASE_API}/public/`;

  const handleSave = async () => {
    if(galleryImages.length === 0 || title === '') {
      return alert.show(<Alert type='error'>É necessário titulo e fotos para salvar uma galeria.</Alert>);
    } else {
      try {
        const imagesName = [];
        for (const value of galleryImages ) {
          if (value.data_url.includes("public")) {
            const dataURL = value.data_url;
            imagesName.push(dataURL.substring(dataURL.indexOf(replace) + replace.length));
          }
        }

        const data = {
          data: { 
            galleryName: name,
            imagesName
          }
        }
        api.defaults.headers.token =  token;
        await api.delete('/images', data)
        .then(async () => {
          for ( const value of galleryImages) {
            const data = new FormData();
            data.append('file', value.file);
            data.append('gallery', name);
            data.append('title', title);
            api.defaults.headers.token =  token;
            await api.post('/gallery', data)
          }
          return alert.show(<Alert type='success'>Dados atualizados com sucesso!</Alert>);
        })
        .catch(() => {
          return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
        })
      }
      catch {
        return alert.show(<Alert type='error'>Erro inesperado aconteceu.</Alert>);
      }
    }
  }
  
  const handleDeleteGallery = async () => {
    const data = {
      data: { 
        galleryName: name,
        title: ''
      }
    }
    
    api.defaults.headers.token =  token; 
    await api.delete('/gallery', data)
    .then(async () => {
      setGallery('');
      setTitle('')
      return alert.show(<Alert type='success'>Galeria limpa com sucesso!</Alert>);
    })
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
    })
  }
  
  const getGallery = async (value) => {
    setGallery([]);
    await api.get(`/gallery/${value}`)
    .then(response => {
      response.data.images.map((path) => {
        return setGalleryImages(events => [...events, {
          data_url: `${BASE_API}/public/${path}`
        }]);
      })
      setTitle(response.data.title);
    })
    .catch(() => {
      return alert.show(<Alert type='error'>Erro de conexão com servidor.</Alert>);
    })
  }
  
  useEffect(() => {
    setTitle(pTitle)
    getGallery(name);
  }, []);
  
  return (
    <S.Wrapper>
      <S.GalleryTittle>
        <h4>{title}</h4>
      </S.GalleryTittle>
      <S.NameGallery>
        <S.Input>
          <Input onChange={event => setTitle(event.target.value)} value={title} label="Nome da Galeria" />
          <Button onClick={handleSave} type="save">
            Salvar
          </Button>
        </S.Input>
        <Button onClick={handleDeleteGallery} type="delete">
          Limpar
        </Button>
      </S.NameGallery>
      <ImageUploading
      multiple
      value={galleryImages}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      >{({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          dragProps,
        }) => (
          <S.Container>
            <S.Buttons>
              <Button
                type={gallery.length >= maxNumber ? 'disable' : 'common'}
                onClick={onImageUpload}
                {...dragProps}
              >
                + Imagens
              </Button>
              <Button type="delete" onClick={onImageRemoveAll}>Limpar Lista</Button>
            </S.Buttons>
            <S.ImageList>
              {imageList.map((image, index) => (
                <S.ImageDisplay key={index}>
                  <img src={image.data_url} alt="" height="300" />
                  <S.ImageButtons>
                    <Button type="common-svg" onClick={() => onImageUpdate(index)}><HiRefresh /></Button>
                    <Button type="warning-svg" onClick={() => onImageRemove(index)}><MdDeleteForever /></Button>
                  </S.ImageButtons>
                </S.ImageDisplay>
              ))}
            </S.ImageList>
          </S.Container>          
        )}
      </ImageUploading>
    </S.Wrapper>
  )
}
