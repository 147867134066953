import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Cookies from "js-cookie";


import { Button, ButtonLink } from "../../components/dumbs/Button";
import { Input } from "../../components/dumbs/Input";
import api from "../../services/api";

import * as S from './styled';

export const RecoveryLogin = () => {
  const alert = useAlert();

  const { id } = useParams();
  
  const history = useHistory();
  
  const [token, setToken] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const getUser = async () => {
    api.defaults.headers.authorization = token;
    await api.get('/user')
    .then(response => {
      setName(response.data.name)
      setEmail(response.data.email)
    })
  }

  const handleRecovery = async () => {
    const data = {
      name,
      email,
      password,
    }
    
    api.defaults.headers.authorization = token;
    await api.put('/user', data)
    .then(() => {
      alert.show("Login recuperado com sucesso!", {type: 'success'});
      setToken('');
      return history.push('/login');
    })
    
  }

  const validatingToken = async (value) => {
    await api.get(`/recovery/${value}`)
    .then(response => {
      setToken(response.data);
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    if(Cookies.get("token")) {
      return history.push('/home');
    }
    validatingToken(id);
    getUser();
  }, [])
  
  return (
    <>
      {
        token !== '' ?
        <S.Container>
          <S.Wrapper>
            <S.Title>Recuperação</S.Title>
            <S.Form>
              <S.Inputs>
                <Input label="E-mail" disabled value={email} type="text" onChange={event => setEmail(event.target.value)} />
                <Input label="Nova senha" type="password" onChange={event => setPassword(event.target.value)} />
              </S.Inputs>
              <S.Buttons>
                <ButtonLink onClick={event => handleRecovery(event)}>Salvar</ButtonLink>
              </S.Buttons>
            </S.Form>
          </S.Wrapper>
        </S.Container>
        : <></>
      }
    </>
  )
}
